@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@qpokychuk/ripple/index.css';

  // @import 'swiper/css';
}

@import '@qpokychuk/inter/index.css';
@import '@qpokychuk/gilroy/index.css';
@import '../ui/ui-core';
@import './style';