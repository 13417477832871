@import 'base';



.auth {
  &-layout {
    flex-grow         : 1;
    display           : grid;
    grid-template-rows: 1fr auto 1fr;
    gap               : 24px;
    padding-top       : 32px;
    padding-bottom    : 32px;

    background: url("../img/auth-bg.png") no-repeat center / cover;

    @media (max-width:theme('screens.lg')) {
      display        : flex;
      flex-direction : column;
      justify-content: center;
    }
  }

  &-form {
    width        : 450px;
    max-width    : 100%;
    border-radius: 24px;
    background   : theme('colors.l3');
    overflow     : hidden;

    @media (max-width:theme('screens.sm')) {
      border-radius: 16px;
    }

    &-header {
      padding: 20px 16px;
      background:
        linear-gradient(100.11deg, rgba(62, 129, 192, 0) 0%, theme('colors.blue') 74.12%), #242424;
      color: #fff;
    }

    &-content {
      padding: 32px 44px 44px;

      @media (max-width:theme('screens.sm')) {
        padding-left : 15px;
        padding-right: 15px;
      }
    }
  }
}

.header {
  position       : sticky;
  top            : 0;
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  background     : theme('colors.l3');
  padding-top    : 12px;
  padding-bottom : 12px;
  z-index        : theme('zIndex.3');

  @media (max-width:theme('screens.lg')) {
    padding-top   : 6px;
    padding-bottom: 6px;
  }

  @media (max-width:theme('screens.sm')) {
    z-index: theme('zIndex.3');

  }

  &-user {
    &-btn {
      padding              : 8px;
      height               : 46px;
      border-radius        : 18px;
      display              : grid;
      grid-template-columns: 1fr 1fr;
      background           : theme('colors.l3');
      box-shadow           : 0 8px 20px 0 rgba(0, 0, 0, 0.06);

      @media (max-width:theme('screens.sm')) {
        padding: 6px;
        height : 38px;
      }

    }

    &-content {
      background   : theme('colors.l3');
      position     : absolute;
      right        : 0;
      top          : calc(100% + 8px);
      padding      : 16px;
      box-shadow   : 0 10px 15px 0 rgba(0, 0, 0, 0.078), 0 10px 25px 0 rgba(0, 0, 0, 0.078);
      width        : 232px;
      border-radius: 12px;
      border       : 1px solid theme('colors.default / 10%');
      transition   : .25s ease;
      z-index      : theme('zIndex.4');

      &:not(.toggle-active) {
        transform     : translateY(20px);
        opacity       : 0;
        pointer-events: none;
      }


      @media (max-width:theme('screens.sm')) {
        position     : fixed;
        bottom       : 0;
        left         : 0;
        width        : 100%;
        top          : auto;
        border-radius: 12px 12px 0 0;
      }
    }

    &-shadow {
      @media (max-width:theme('screens.sm')) {
        position  : fixed;
        inset     : 0;
        z-index   : theme('zIndex.3');
        background: rgba(0, 0, 0, 0.5);
        transition: .25s ease;

      }

    }

    &:not(.toggle-active) &-shadow {
      opacity       : 0;
      pointer-events: none;
    }
  }
}

.dashboard {
  &-layout {
    padding-top   : 32px;
    padding-bottom: 32px;
  }
}

.sidebar {
  position     : sticky;
  top          : 100px;
  background   : #0E143A;
  color        : #fff;
  width        : 362px;
  flex-shrink  : 0;
  padding      : 32px 24px;
  border-radius: 24px;
  z-index      : theme('zIndex.3');

  @media (max-width:theme('screens.lg')) {
    position     : fixed;
    left         : 0;
    top          : 0;
    height       : 100%;
    overflow     : hidden;
    width        : 300px;
    max-width    : 100%;
    z-index      : theme('zIndex.2');
    border-radius: 0;
    transition   : .25s ease;

    &:not(.toggle-active) {
      transform: translateX(-100%);
    }
  }


  @media (max-width:theme('screens.sm')) {
    padding-top: 80px;
  }

  &-shadow {
    position  : fixed;
    inset     : 0;
    z-index   : calc(theme('zIndex.2') - 1);
    background: rgba(0, 0, 0, 0.5);
    transition: .25s ease;

    @media (min-width:theme('screens.lg')) {
      display: none;
    }
  }

  &:not(.toggle-active)~&-shadow {
    opacity       : 0;
    pointer-events: none;
  }

}

.content {
  flex-grow: 1;
  max-width: 100%;
}

.tabs {
  --padding: 20px;

  display      : flex;
  border-bottom: 1px solid theme('colors.default / 10%');
  gap          : calc(2 * var(--padding));
  margin-top   : calc(-1 * var(--padding));
  overflow     : auto;
  width        : calc(100% + 30px);
  margin-left  : -15px;
  padding-left : 15px;
  padding-right: 15px;

  @media (max-width:theme('screens.lg')) {
    --padding: 10px;

  }

  &-item {
    font-family : theme('fontFamily.alt');
    font-weight : 600;
    font-size   : 18px;
    padding     : var(--padding);
    margin-left : calc(-1 * var(--padding));
    margin-right: calc(-1 * var(--padding));
    position    : relative;
    white-space : nowrap;

    @media (max-width:theme('screens.sm')) {
      font-size: 14px;
    }


    &:not(.active) {
      color: theme('colors.default / 60%');

    }

    @media(hover) {
      &:not(.active):hover {
        color: theme('colors.primary');
      }
    }


    &.active::before {
      content   : '';
      position  : absolute;
      bottom    : 0;
      height    : 1px;
      left      : var(--padding);
      right     : var(--padding);
      background: theme('colors.primary');
    }
  }
}

.order {
  background: theme('colors.l3');
  border    : 1px solid theme('colors.default / 10%');
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.04);

  border-radius: 24px;

  @media (max-width:theme('screens.sm')) {
    border-radius: 16px;
  }

  &-header {
    padding: 24px;

    @media (max-width:theme('screens.sm')) {
      padding: 20px 15px;
    }
  }

  &-content {
    padding: 24px;

    @media (max-width:theme('screens.sm')) {
      padding: 20px 15px;
    }
  }
}

.dropdown {
  background   : theme('colors.l3');
  position     : absolute;
  top          : calc(100% + 8px);
  box-shadow   : 0 10px 15px 0 rgba(0, 0, 0, 0.078), 0 10px 25px 0 rgba(0, 0, 0, 0.078);
  border-radius: 12px;
  border       : 1px solid theme('colors.default / 10%');
  transition   : .25s ease;
  z-index      : theme('zIndex.1');

  &:not(.toggle-active) {
    transform     : translateY(20px);
    opacity       : 0;
    pointer-events: none;
  }

  @media (max-width:theme('screens.md')) {
    position     : fixed;
    bottom       : 0;
    left         : 0;
    width        : 100%;
    top          : auto;
    border-radius: 12px 12px 0 0;
    z-index      : theme('zIndex.4');
  }

  &-shadow {
    @media (max-width:theme('screens.md')) {
      position  : fixed;
      inset     : 0;
      z-index   : theme('zIndex.3');
      background: rgba(0, 0, 0, 0.5);
      transition: .25s ease;

    }

  }

  &:not(.toggle-active)~&-shadow {
    opacity       : 0;
    pointer-events: none;
  }
}

.dialog {
  padding      : 0;
  background   : theme('colors.l3');
  box-shadow   : 0 10px 15px 0 rgba(0, 0, 0, 0.039);
  border-radius: 24px;
  overflow     : hidden;
  max-width    : 100%;

  @media (max-width:theme('screens.sm')) {
    border-radius: 16px;
  }

}

.rating-field {
  &-item {
    color     : theme('colors.black / 20%');
    cursor    : pointer;
    transition: .2s ease;

    @media(hover) {
      &:hover {
        transform: scale(1.1);
      }
    }

    &:has(:checked) {
      transform : scale(1.2);
      transition: transform .35s var(--elastic), color .2s ease;

    }
  }

  @media(hover) {
    &:hover &-item {
      color: theme('colors.yellow');
    }

    &-item:hover~&-item {
      color: theme('colors.black / 20%');
    }
  }

  &:has(:checked) &-item {
    color: theme('colors.yellow');
  }

  &-item:has(:checked)~&-item {
    color: theme('colors.black / 20%');
  }
}

.review-dialog {
  width: 386px;

}

.confirm-dialog,
.message-dialog {
  width: 450px;
}

.info-dialog {
  width: 372px;
}

.structure-dialog {
  width: 970px;
}

.copy-notification {
  position      : absolute;
  padding       : 8px 12px;
  border-radius : 8px;
  font-size     : 12px;
  z-index       : 2;
  background    : theme('colors.grey');
  color         : #fff;
  opacity       : 0;
  pointer-events: none;
  transition    : .25s ease;
  left          : calc(100% + 8px);
  transform     : translateX(10px);

  @media (max-width:theme('screens.md')) {
    left : auto;
    right: calc(100% + 8px);
  }

  &.active {
    background: theme('colors.green');
    opacity   : 1;
    transform : none;
  }

  @media(hover) {
    &:is(.group:hover &) {
      opacity  : 1;
      transform: none;
    }
  }
}